export const AVAILABLE_FACILITIES = [
    'parking',
    'wheelchair',
    'wudu',
    'womens_area',
    'prayer_hall',
    'quran_classes',
    'islamic_library',
    'funeral_service',
    'community_hall',
    'children_area',
    'shoe_rack',
    'ac',
    'wifi',
    'restaurant',
    'shop'
] as const;

export interface MasjidDataInterface {
    id: string
    name: string
    description: string | null
    openHour: string
    closeHour: string
    email: string | null
    facebookUrl: string | null
    instagramUrl: string | null
    whatsapp: string | null
    telephone: string | null
    mobile: string | null
    country: string | null
    state: string | null
    city: string | null
    street: string | null
    postalCode: string | null
    houseNumber: string | null
    neighborhood: string | null
    latitude: string | null
    longitude: string | null
    formattedAddress: string | null
    leaderName: string
    leaderDescription: string | null
    leaderImageId: string | null
    NgoName: string
    NgoTelephone: string | null
    NgoMobile: string | null
    NgoEmail: string | null
    ImagesIds: Array<string>
    ownerId: string
    administratorsIds: Array<string>
    lessonIds: Array<string>
    lessons: Array<MasjidLessonsInterface>
    masjidFacilities: Array<typeof AVAILABLE_FACILITIES[number]>
}

export const initialMasjidDataInterface: MasjidDataInterface = {
    id: '',
    name: '',
    description: '',
    openHour: '',
    closeHour: '',
    email: '',
    facebookUrl: '',
    instagramUrl: '',
    whatsapp: '',
    telephone: '',
    mobile: '',
    country: '',
    state: '',
    city: '',
    street: '',
    postalCode: '',
    houseNumber: '',
    neighborhood: '',
    latitude: '',
    longitude: '',
    formattedAddress: '',
    leaderName: '',
    leaderDescription: '',
    leaderImageId: '',
    NgoName: '',
    NgoTelephone: '',
    NgoMobile: '',
    NgoEmail: '',
    ImagesIds: [],
    ownerId: '',
    administratorsIds: [],
    lessonIds: [],
    lessons: [],
    masjidFacilities: [],
}

export interface MasjidLessonsInterface {
    lessonId: string
    name: string
    language: string
    duration: string
    start: string
    end: string
    instructor: string
    masjid_id: string
    days: Array<string>
}
